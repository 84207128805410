import { createStore } from '@ngneat/elf';
import { getEntity, selectEntityByPredicate, upsertEntitiesById, withEntities } from '@ngneat/elf-entities';
import {
    createRequestsCacheOperator,
    isRequestCached,
    updateRequestCache,
    withRequestsCache
} from '@ngneat/elf-requests';
import { BookFilterI, BookI, BookVersionI } from '../models/book';
import { Injectable } from '@angular/core';
import {
    getPaginationData,
    PaginationData,
    selectCurrentPageEntities,
    setCurrentPage,
    setPage,
    updatePaginationData,
    withPagination
} from '@ngneat/elf-pagination';
import { Pageable } from '../providers/pagination-provider';
import * as _ from 'lodash';
import { HttpPaginationResponseI } from '../models/http';
import { Observable } from 'rxjs/internal/Observable';

export interface IBookVersionState extends BookVersionI {
    id: string; // group id of book
}

const store = createStore(
    { name: 'adminBookVersion' },
    withEntities<IBookVersionState>(),
    withPagination(),
    withRequestsCache<
        | `admin-book-versions-${number}`
        | `admin-book-versions-thumbnail-${number}`
        | `admin-book-versions-page-${number}`
    >()
);

export const skipWhileAdminBookVersionCached = createRequestsCacheOperator(store);
export enum CACHED_ADMIN_BOOK_VERSION_KEYS {
    DEFAULT = 'admin-book-versions',
    THUMBNAIL = 'admin-book-versions-thumbnail',
    PAGINATION = 'admin-book-versions-page'
}

@Injectable({ providedIn: 'root' })
export class AdminBookVersionRepository {
    activeFilters: BookFilterI;
    currentPageable: Pageable;
    bookVersionsOnCurrentPage$ = store.pipe(selectCurrentPageEntities());

    getPaginationData(): PaginationData & {
        pages: Record<number, number[]>;
    } {
        return store.query(getPaginationData());
    }

    setActiveFilters(filters: BookFilterI): void {
        this.activeFilters = JSON.parse(JSON.stringify(filters)); // to remove objects and arrays pointers
    }

    clearCacheIfDistinctFiltersOrSize(filters: BookFilterI, size: number): void {
        if (!_.isEqual(filters, this.activeFilters) || this.getPaginationData().perPage !== size) {
            this.clearPaginationCache();
        }
    }

    clearPaginationCache(): void {
        for (const key in this.getPaginationData()?.pages) {
            if (Object.prototype.hasOwnProperty.call(this.getPaginationData().pages, key)) {
                store.update(
                    updateRequestCache(`${CACHED_ADMIN_BOOK_VERSION_KEYS.PAGINATION}-${+key}`, { value: 'none' })
                );
            }
        }
    }

    updateCurrentPageIfIsCached(page: number): void {
        if (store.query(isRequestCached(`${CACHED_ADMIN_BOOK_VERSION_KEYS.PAGINATION}-${page}`))) {
            store.update(setCurrentPage(page));
        }
    }

    addPaginationBooks(paginationResponse: HttpPaginationResponseI<BookVersionI[]>, pageable: Pageable): void {
        this.currentPageable = pageable;

        store.update(updateRequestCache(`${CACHED_ADMIN_BOOK_VERSION_KEYS.PAGINATION}-${paginationResponse.number}`));

        paginationResponse.content.forEach(bookVersion => {
            if (!bookVersion.book.thumbnail) {
                delete bookVersion.book.thumbnail; // if it's an update it can override the thumbnail if it is missing
            }
            store.update(
                upsertEntitiesById(bookVersion.book.group, {
                    creator: () => ({
                        id: bookVersion.book.group,
                        ...bookVersion
                    }),
                    updater: entity => ({
                        ...entity,
                        ...bookVersion,
                        book: {
                            // to keep thumbnail if it's an update
                            ...entity.book,
                            ...bookVersion.book
                        }
                    })
                })
            );
        });

        store.update(
            updatePaginationData({
                currentPage: paginationResponse.number,
                perPage: paginationResponse.size,
                total: paginationResponse.totalElements,
                lastPage: paginationResponse.totalPages
            }),
            setPage(
                paginationResponse.number,
                paginationResponse.content.map(c => c.book.group)
            ),
            setCurrentPage(paginationResponse.number)
        );
    }

    selectBook$(id: number): Observable<BookI> {
        return store.pipe(
            selectEntityByPredicate(({ book, versions }) => book?.id === id || !!versions.find(v => v.id === id), {
                pluck: ({ book, versions }) => (book?.id === id ? book : versions.find(v => v.id === id))
            })
        );
    }

    updateBook(payload: BookI): void {
        const bookUpdated = store.query(getEntity(payload.group));
        if (bookUpdated[this.currentPageable.sort] !== payload[this.currentPageable.sort]) {
            // if sort field has been updated it can affect order
            this.clearPaginationCache();
        }
        this.upsertBook(payload);
    }

    upsertBook(payload: BookI): void {
        if (payload.thumbnail) {
            store.update(updateRequestCache(`${CACHED_ADMIN_BOOK_VERSION_KEYS.THUMBNAIL}-${payload.id}`));
        } else {
            delete payload.thumbnail;
            store.update(updateRequestCache(`${CACHED_ADMIN_BOOK_VERSION_KEYS.DEFAULT}-${payload.id}`));
        }
        store.update(
            upsertEntitiesById(payload.group, {
                creator: () => ({
                    id: payload.group,
                    book: payload,
                    versions: []
                }),
                updater: entity => {
                    const versions = [...entity.versions.filter(v => v.id !== payload.id), { ...payload }];
                    return { ...entity, book: { ...entity.book, ...payload, id: entity.book.id }, versions };
                }
            })
        );
    }
}
