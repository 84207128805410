import { SectorI } from './sector';

export interface CompanyI {
    id?: number;
    isActive?: boolean;
    autoLogon?: boolean;
    name?: string;
    numberOfUsers?: number;
    numberOfActiveUsers?: number;
    type?: string;
    licenses?: string;
    managers?: string;
    marketIds?: number[];
    sector?: SectorI;
    uuidOrganizationUnit?: string;
    vat?: string;
    legalAddress?: string;
    billingAddress?: string;
    billingEmail?: string;
    initialized?: boolean;
    status?: CompanyStatusE;
    requestedUpgrade?: boolean;
    onTrial?: boolean;
    expirationDate?: string;
    createdDate?: string;
    numberOfPendingInvitations?: number;
}

export enum CompanyStatusE {
    PENDING_UPGRADE_PLAN = 'PENDING_UPGRADE_PLAN',
    PENDING_VALIDATION = 'PENDING_VALIDATION',
    VALIDATED = 'VALIDATED',
    DELETED = 'DELETED',
    PENDING_ANNUAL_REVIEW = 'PENDING_ANNUAL_REVIEW'
}

export interface ICompanyFilter {
    name?: string;
    marketId?: number;
    sectorId?: number;
    status?: string;
    requestedUpgrade?: boolean;
    onTrial?: boolean;
}
