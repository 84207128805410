import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styles: ['img { cursor: pointer; }']
})
export class LogoComponent {
    @Input() type: 'default' | 'picto' | 'big';
}
