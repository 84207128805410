import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FacetKeyHitsI, HitsDataI } from 'src/app/models/facets';
import { FilterCategoryI, PairKeyLabelI } from 'src/app/models/filter';
import { AttributesI } from 'src/app/models/publication';
import { AuthorI } from 'src/app/models/author';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentFilterService } from '../../service/document-filter.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { Configuration } from 'src/app/constant/configuration';
import { CategoryService } from '../dropdown-inline/category.service';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-dropdown-filter',
    templateUrl: './dropdown-filter.component.html',
    styleUrls: ['./dropdown-filter.component.scss']
})
export class DropdownFilterComponent {
    fieldFilterValue = '';
    @Input() category: FilterCategoryI;
    @Input() attributes$: BehaviorSubject<AttributesI>;
    @Input() facetsHits: BehaviorSubject<HitsDataI>;
    @Input() showNumberOfSelected = true;
    @Output() attributesChange = new EventEmitter<AttributesI>();
    @Input() orderByDesc = false;

    constructor(
        private documentFilterService: DocumentFilterService,
        private configuration: Configuration,
        private languageService: LanguageService,
        private categoryService: CategoryService
    ) {}

    get filterTypeContext(): string {
        return '*' + this.configuration.translationContext.FILTER_TYPE_CONTEXT + '*';
    }

    get facets$(): Observable<FacetKeyHitsI[]> {
        return this.facetsHits.pipe(
            filter(hits => hits != null && hits.hits != null),
            map(({ hits }) => hits[this.categoryKey])
        );
    }

    get categoryKey(): string {
        return this.documentFilterService.getCategoryKey(this.category.label);
    }

    get attributesValue(): AttributesI {
        return this.attributes$.value;
    }

    get attributesCategory(): string[] {
        return this.attributesValue[this.documentFilterService.getCategoryKey(this.category.label)];
    }

    apply(): void {
        const attributesCopy = this.attributesValue;
        this.category.categories.forEach(subCat => {
            const attributeKey = this.categoryKey;
            if (attributesCopy[attributeKey] === undefined) {
                attributesCopy[attributeKey] = [];
            }
            const index = attributesCopy[attributeKey].indexOf(subCat.key.toString());
            if (index === -1 && subCat.selected.value) {
                attributesCopy[attributeKey].push(subCat.key.toString());
            } else if (index >= 0 && !subCat.selected.value) {
                attributesCopy[attributeKey] = attributesCopy[attributeKey].filter(savedAttr => {
                    return savedAttr !== subCat.key.toString();
                });
            }
        });
        this.category.updateNumberOfSelected();
        this.attributes$.next(attributesCopy);
        this.attributesChange.emit(attributesCopy);
    }

    /**
     * Removes all selected filters in dropdown, and also those that were already applied by looking at the filter attributes
     */
    remove(): void {
        const attributesCopy = this.attributesValue;
        this.fieldFilterValue = '';
        attributesCopy[this.documentFilterService.getCategoryKey(this.category.label)] = this.getAttributesToKeep();
        this.category.unselect();
        this.attributes$.next(attributesCopy);
        this.attributesChange.emit(attributesCopy);
    }

    /**
     * Sometimes different dropdowns can share the same attribute in the filter
     * for example book and magazines are both under 'source' category.
     * To avoid removing filters from both of them on filter remove
     * There is a prefix for books in key 'B_' and in the case of magazines is 'MC_'
     */
    getAttributesToKeep(): string[] {
        const attributes = this.attributesCategory != null ? this.attributesCategory : [];
        switch (this.category.label) {
            case 'book':
                return attributes.filter(el => !el.includes('B_'));
            case 'magazine':
                return attributes.filter(el => !el.includes('MC_'));
            default:
                return [];
        }
    }

    /**
     * When dropdown closes should verify what subcategories were selected before and keep them selected
     * And should unselect those subcategories that are not in the current filter attributes
     * When there is no attributes for this category all should be unselected
     */
    openChanged(open: boolean): void {
        const currentAttributes = this.attributesValue[this.categoryKey]; // attributes for this category
        if (!open) {
            this.category.categories.forEach(cat => {
                if (currentAttributes === undefined || !currentAttributes.includes(cat.key)) {
                    cat.selected.next(false);
                } else if (currentAttributes.includes(cat.key)) {
                    cat.selected.next(true);
                }
            });
        }
    }

    updateAuthorSubCategories(authors: AuthorI[]): void {
        // clean categories
        this.category.categories = this.category.categories.filter(subCat => subCat.selected.value); // keep only selected categories
        // remove authors that already in the subcats list
        authors = authors.filter(author => {
            for (const subCat of this.category.categories) {
                if (author.id.toString() === subCat.key) {
                    return false;
                }
            }
            return true;
        });
        // map authors to PairKeyLabel
        const subCats: PairKeyLabelI[] = authors.map(author => {
            return { key: author.id.toString(), label: author.fullName };
        });
        this.category.categories = [
            ...this.category.categories,
            ...this.categoryService.createSubCategories(
                this.category.label,
                subCats,
                false,
                this.category.updateNumberOfSelected.bind(this.category),
                this.attributes$
            )
        ];
    }

    translateEntry(label: string): string {
        if (this.isTranslatable()) {
            return this.languageService.instant('*' + this.getContext() + '*' + label);
        }
        return label;
    }

    getContext(): string {
        switch (this.category.label) {
            case 'workspace':
                return this.configuration.translationContext.WORKSPACE;
            case 'language':
                return this.configuration.translationContext.LANGUAGE;
            case 'territory':
                return this.configuration.translationContext.TERRITORY;
            case 'nature':
                return this.configuration.translationContext.NATURE;
            case 'jurisdiction':
                return this.configuration.translationContext.JURISDICTION;
            case 'guidelineSource':
                return this.configuration.translationContext.GUIDELINE;
            default:
                return this.category.label;
        }
    }

    isTranslatable(): boolean {
        return this.configuration.translatableFilters.includes(this.category.label);
    }

    isApplied(): boolean {
        return this.category.numberOfSelected.value > 0;
    }
}
