import { Component } from '@angular/core';
import { LanguageService } from '../shared/service/language.service';
import { Configuration } from '../constant/configuration';
import { CookieService } from 'ngx-cookie';
import { MarketE } from '../models/market';
import { AuthenticateService } from '../services/authenticate.service';
import { CurrentUserI, UserDefaultRolesE } from '../models/user';
import { RestrictedAccessPopupService } from '../services/restricted-access-popup.service';
import { stringIsUuid } from '../shared/utils/stringUtils';

@Component({
    selector: 'app-main',
    templateUrl: 'main.component.html'
})
export class MainComponent {
    minutesBeforeShowExpiredBanner = 60 * 24 * 5; // 5 days
    constructor(
        private languageService: LanguageService,
        private configuration: Configuration,
        private cookies: CookieService,
        private authenticateService: AuthenticateService,
        private restrictedAccessPopupService: RestrictedAccessPopupService
    ) {}

    get userMarket(): string {
        return this.authenticateService.currentUser?.markets.includes(MarketE.BE) ? 'be' : 'lu';
    }

    get cookiesUrl(): string {
        return this.configuration.documents.cookies[this.userMarket][this.twoLetterLanguage].file;
    }

    get twoLetterLanguage(): string {
        return this.languageService.selectedLanguageData.twoLettersLanguage;
    }

    get consented(): boolean {
        return this.cookies.get('_LexNowConsented') === 'true';
    }

    get currentUser(): CurrentUserI {
        return this.authenticateService.currentUser;
    }

    get companyNameForSubjectOfMail(): string {
        return stringIsUuid(this.currentUser.companyName) ? '' : `(${this.currentUser.companyName})`;
    }

    get isTrialCompanyManager(): boolean {
        return this.currentUser && this.currentUser.roles.includes(UserDefaultRolesE.TRIAL_COMPANY_MANAGER);
    }

    get canViewTrialExpiredBanner(): boolean {
        return (
            this.currentUser &&
            this.currentUser.companyOnTrial &&
            this.currentUser.companyMinutesBeforeExpirationDate &&
            this.currentUser.companyMinutesBeforeExpirationDate < this.minutesBeforeShowExpiredBanner
        );
    }

    acceptCookies(): void {
        const expiration = new Date();
        expiration.setDate(expiration.getDate() + 30);
        this.cookies.put('_LexNowConsented', 'true', { expires: expiration });
    }

    upgradePlan(): void {
        this.restrictedAccessPopupService.show({ askToUpgrade: true });
    }
}
