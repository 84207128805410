import { AbstractCRUDsService } from './abstract_cruds.service';
import { Injectable } from '@angular/core';
import { MixpanelService } from './mixpanel.service';
import { HttpClientService } from './http-client.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserPilotService } from './user-pilot.service';
import { CurrentUserI } from '../models/user';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MARKET_CODE_BE, MARKET_CODE_LU } from '../models/market';
import { Credentials, LocalStorageAdapter } from '../shared/utils/local-storage.adapter';
import { MarketService } from './market.service';
import { WorkspaceService } from './workspace.service';
import { CompanyManagerI } from '../models/companyManager';
import { RoleService } from './role.service';
import { ResetPasswordTokenI } from '../models/resetPasswordToken';
import { Configuration } from '../constant/configuration';

@Injectable({ providedIn: 'root' })
export class AuthenticateService extends AbstractCRUDsService {
    constructor(
        public localStorageAdapter: LocalStorageAdapter,
        public http: HttpClient,
        public httpService: HttpClientService,
        private cookieService: CookieService,
        private mixPanelService: MixpanelService,
        private userPilotService: UserPilotService,
        private marketService: MarketService,
        private roleService: RoleService,
        private workspaceService: WorkspaceService,
        private configuration: Configuration
    ) {
        super('/authenticate', httpService);
        setInterval(() => {
            if (this.isLogged()) {
                void this.isAlive();
            }
        }, 60000 * 5); // 5 minutes
    }

    /**
     * deprecated: use observable instead
     */
    currentUser: CurrentUserI;
    private currentUserSubject = new BehaviorSubject<CurrentUserI | null>(null);
    currentUser$: Observable<CurrentUserI | null> = this.currentUserSubject.asObservable();
    isAutoLogonUser$ = this.currentUser$.pipe(map(user => user && user.autologon));
    currentUserWorkspaces$ = combineLatest([this.currentUser$, this.workspaceService.workspaces$]).pipe(
        map(([user, workspaces]) => {
            if (user == null) {
                return [];
            } else {
                return workspaces.filter(workspace => {
                    return user.workspaces.indexOf(workspace.id) >= 0;
                });
            }
        })
    );
    currentUserMarkets$ = combineLatest([this.currentUser$, this.marketService.allMarkets$]).pipe(
        map(([user, markets]) => {
            if (user == null) {
                return [];
            } else {
                return markets.filter(market => {
                    return user.markets.indexOf(market.id) >= 0;
                });
            }
        })
    );
    belongsToBelgiumMarket$ = this.currentUserMarkets$.pipe(
        map(markets => {
            return markets.find(market => market.code === MARKET_CODE_BE) != null;
        })
    );

    belongsToLuxembourgMarket$ = this.currentUserMarkets$.pipe(
        map(markets => {
            return markets.find(market => market.code === MARKET_CODE_LU) != null;
        })
    );

    isLogged(): boolean {
        return this.currentUser != null || this.cookieService.get('JSESSIONID') != null;
    }

    async login(credential: Credentials): Promise<any> {
        credential.timestamp = Date.now();
        this.localStorageAdapter.credentials = credential;
        const data: string =
            'j_username=' +
            encodeURIComponent(credential.email) +
            '&j_password=' +
            encodeURIComponent(credential.password) +
            '&submit=Login';
        const configuration = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            ignoreAuthModule: 'ignoreAuthModule'
        };
        return this.httpService
            .post('/authentication', data, configuration)
            .toPromise()
            .then(async result => {
                this.mixPanelService.track(this.mixPanelService.constants.actions.registration.login, {});
                const user: CurrentUserI = await this.getCurrentUser();
                await this.marketService.getMarkets();
                this.mixPanelService.identify(user);
                this.userPilotService.identify(user);
                return result;
            });
    }

    /**
     * Get the previous credentials
     */
    getPreviousCredentials(): Credentials | null {
        return this.localStorageAdapter.credentials;
    }

    /**
     * Login service to authenticate a user via a credential
     */
    async logout(): Promise<any> {
        this.localStorageAdapter.credentials = null;
        this.currentUser = undefined;
        this.currentUserSubject.next(null);
        // we needed to remove session roles, after logout
        // to force actualize on next login
        this.roleService.resetSessionRoles();
        this.userPilotService.closeSession();
        return this.httpService.get('/logout').toPromise();
    }

    /**
     * Launch a connection request to the server to check if the current user is still connected
     * This method is usefull to trigger a lost connection event if the session has expired.
     */
    async checkCurrentUserValidity(): Promise<any> {
        return this.httpService.get('/user/current');
    }

    /**
     * Returns the current user and its related information
     */
    async getCurrentUser(): Promise<CurrentUserI> {
        return new Promise(resolve => {
            if (this.currentUser) {
                resolve(this.currentUser);
            } else {
                this.httpService.get('/user/current').subscribe(
                    result => {
                        this.currentUser = result;
                        this.currentUserSubject.next(result);
                        resolve(result);
                    },
                    () => {
                        resolve(null);
                    }
                );
            }
        });
    }

    refreshCurrentUser(): void {
        this.httpService.get('/user/current').subscribe((result: CurrentUserI) => {
            this.currentUser = result;
            this.currentUserSubject.next(result);
        });
    }

    /**
     * Accept the terms
     */
    async acceptTerms(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.httpService.post('/user/accept-terms').subscribe(
                () => {
                    this.currentUser = null;
                    this.currentUserSubject.next(null);
                    resolve();
                },
                () => {
                    reject();
                }
            );
        });
    }

    async checkTokenValidity(token: string): Promise<any> {
        return firstValueFrom(this.http.post('/u/check/' + token, {}));
    }

    /**
     * Send an email to the user who forgets his password
     */
    forget(payload): Observable<void> {
        return this.http.post<void>('/u/forget', payload);
    }

    /**
     * Reset the user password
     */
    reset(passwordToken: ResetPasswordTokenI): Observable<void> {
        return this.http.post<void>('/u/reset', passwordToken);
    }

    createPassword(passwordToken: ResetPasswordTokenI): Observable<void> {
        return this.http.post<void>('/u/create-password', passwordToken);
    }

    /**
     * Check if the current user is a temporary user.
     */
    isTemporaryUser(): boolean {
        if (this.currentUser !== undefined) {
            return this.currentUser.roles.includes('TEMPORARY_USER');
        } else {
            return false;
        }
    }

    /**
     * Check if the current user is an auto-logon user.
     */
    isAutoLogonUser(): boolean {
        return this.currentUser != null && this.currentUser.autologon;
    }

    /**
     * Send an heartbeat to the server to keep alive the session.
     */
    async isAlive(): Promise<any> {
        return firstValueFrom(
            this.httpService.get('/user/alive', {
                headers: new HttpHeaders({ skipSpinner: 'true' })
            })
        );
    }

    /**
     * Creates a user in the company associated to the given token.
     */
    async createUserFromToken(token: string, payload: CompanyManagerI): Promise<any> {
        return firstValueFrom(this.http.post(` /u/user/create/${token}`, payload));
    }

    userHasAcceptedLatestTerms(): boolean {
        return (
            this.currentUser &&
            this.currentUser.termsAndAgreementsDate &&
            (() => {
                const strDate = this.configuration.documents.terms.date;
                const dateParts = strDate.split('/');
                const dateObject = new Date(
                    parseInt(dateParts[2], 10),
                    parseInt(dateParts[1], 10) - 1,
                    parseInt(dateParts[0], 10)
                );
                const agreementsDate = new Date(this.currentUser.termsAndAgreementsDate);
                return agreementsDate > dateObject;
            })()
        );
    }
}
