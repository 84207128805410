import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { WorkspaceI } from '../models/workspace';
import { HttpClient } from '@angular/common/http';
import { concat, EMPTY, tap } from 'rxjs';
import {
    CACHE_KEY_WORKSPACES,
    CACHE_KEY_WORKSPACES_FOR_SESSION_MARKET,
    skipWhileWorkspaceCached,
    WorkspaceRepository
} from '../store';
import { catchError } from 'rxjs/operators';
import { MessageBus } from '../messaging/MessageBus';
import { Configuration } from '../constant/configuration';

@Injectable({ providedIn: 'root' })
export class WorkspaceService {
    workspaces$ = this.workspaceRepository.workspaces$;
    workspacesForCurrentMarket$ = this.workspaceRepository.workspacesForCurrentMarket$;

    constructor(
        public httpClient: HttpClient,
        private workspaceRepository: WorkspaceRepository,
        messageBus: MessageBus,
        configuration: Configuration
    ) {
        messageBus.channel(configuration.messageBusChannels.USER_LOGIN_CHANNEL).subscribe(() => {
            this.fetchAll().subscribe();
        });
        this.fetchAll().subscribe();
    }

    fetchAll(): Observable<object> {
        return concat(this.fetchWorkspaces(), this.fetchWorkspacesForCurrentMarket());
    }

    fetchWorkspaces(): Observable<WorkspaceI[]> {
        return this.httpClient.get<WorkspaceI[]>('/webfront/workspace/').pipe(
            catchError(() => EMPTY),
            tap(workspaces => this.workspaceRepository.setWorkspaces(workspaces)),
            skipWhileWorkspaceCached(CACHE_KEY_WORKSPACES)
        );
    }

    fetchWorkspacesForCurrentMarket(): Observable<WorkspaceI[]> {
        return this.httpClient.get<WorkspaceI[]>('/webfront/workspace/for-session-market').pipe(
            catchError(() => EMPTY),
            tap(workspaces => this.workspaceRepository.setWorkspacesForCurrentMarket(workspaces)),
            skipWhileWorkspaceCached(CACHE_KEY_WORKSPACES_FOR_SESSION_MARKET)
        );
    }
}
