import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AppE } from '../../../models/app';

@Directive({
    selector: '[appIsPoliteaApp]'
})
export class IsPoliteaAppDirective implements OnInit {
    @Input() appIsPoliteaApp = true;
    constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>) {}

    ngOnInit(): void {
        if ((environment.app === AppE.politeia) === this.appIsPoliteaApp) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
