import { CanMatchFn } from '@angular/router';
import { environment } from '../../environments/environment';
import { AppE } from '../models/app';

export const APP_GUARD: {
    isNotPolitea: CanMatchFn;
} = {
    isNotPolitea: (): boolean => {
        return checkCanAccess();
    }
};

function checkCanAccess(): boolean {
    return environment.app !== AppE.politeia;
}
