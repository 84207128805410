import { Injectable } from '@angular/core';
import { Configuration } from '../../constant/configuration';
import { MessageBus } from '../../messaging/MessageBus';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

interface IRouteHistory {
    path: string;
    queryParams?: { [key: string]: string };
    stateParams: { [key: string]: string };
}

interface IWindowNavigation {
    canGoForward: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class PreviousRouteService {
    // Note: the last route added, is the current route
    readonly MAXIMUM_ROUTES = 50;
    private _isEmpty: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public readonly isEmpty$: Observable<boolean> = this._isEmpty.asObservable();
    private advancedBrowserHistory: IRouteHistory[] = [];
    windowNavigation: IWindowNavigation;

    constructor(
        private messageBus: MessageBus,
        private configuration: Configuration,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location
    ) {
        this.windowNavigation = (window as any).navigation as IWindowNavigation;
    }

    initialize(): void {
        this.messageBus
            .channel(this.configuration.messageBusChannels.USER_LOGOUT_CHANNEL)
            .subscribe(() => this.logout());

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (this.isBackMovement()) {
                    const unauthorizedBook =
                        event.urlAfterRedirects.match(/o\/book\/detail\/[0-9]+\/content/) &&
                        this.advancedBrowserHistory.slice(-1)[0] &&
                        this.advancedBrowserHistory.slice(-1)[0].path.match(/o\/book\/detail\/[0-9]+\/purchase/);

                    this.removeLastRoute();
                    if (unauthorizedBook) {
                        // because it shouldn't open the same book that the user has no access to, avoiding entering a recursion
                        this.goBack();
                    }
                } else {
                    this.addRouteToBrowserHistory(event);
                }
            }
        });
    }

    private addRouteToBrowserHistory(event: NavigationEnd): void {
        const currentRoute = {
            path: event.urlAfterRedirects, // event.urlAfterRedirects return path serialized with params and queryParams
            queryParams: this.route.snapshot.queryParams,
            stateParams: this.location.getState() as { [key: string]: string }
        };
        if (this.advancedBrowserHistory.length === this.MAXIMUM_ROUTES) {
            this.advancedBrowserHistory = [...this.advancedBrowserHistory.slice(1, this.MAXIMUM_ROUTES), currentRoute];
        } else {
            this.advancedBrowserHistory.push(currentRoute);
        }
        this._isEmpty.next(this.advancedBrowserHistory.length <= 1);
    }

    public goBack(): void {
        if (this.advancedBrowserHistory.length > 1) {
            window.history.back();
        } else {
            void this.router.navigate(['/']);
        }
    }

    getPreviousRoute(): IRouteHistory {
        if (this.advancedBrowserHistory.length > 1) {
            return this.advancedBrowserHistory[this.advancedBrowserHistory.length - 2];
        } else {
            return null;
        }
    }

    isBackMovement(): boolean {
        // if user can go forward, it means that is a going back
        return this.windowNavigation && this.windowNavigation.canGoForward && this.advancedBrowserHistory.length > 0;
    }

    private logout(): void {
        this.advancedBrowserHistory = [];
        this._isEmpty.next(true);
    }

    private removeLastRoute(): void {
        if (this.advancedBrowserHistory.length > 1) {
            this.advancedBrowserHistory.pop();
            this._isEmpty.next(this.advancedBrowserHistory.length <= 1);
        }
    }

    public isEmpty(): boolean {
        return this._isEmpty.getValue();
    }
}
