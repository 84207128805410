import { LicenseI } from './license';
import { PublicationFilterI } from './publication';
import { FreeUserTypeE } from './companyManager';

export enum UserStatusE {
    ACTIVE = 'ACTIVE',
    SUSPENDED = 'SUSPENDED',
    DELETED = 'DELETED'
}

export interface UserI {
    uuid: string;
    email: string;
    firstname: string;
    name: string;
    language: string;
    companyUuid: string;
    companyName: string;
    password?: string;
    newPassword?: string;
    roles: string[];
    permissions: string[];
    expirationDate: string;
    alternativeEmail?: string;
    alertSubscription?: boolean;
    license?: LicenseI;
    isLocked: boolean;
    status: UserStatusE;
    props: UserPropsI;
    markets: number[];
    expired?: boolean;
}

export interface CurrentUserI extends UserI {
    timeBeforeExpiration: number;
    graceLoginsRemaining: number;
    usingGraceLogins: boolean;
    autologon: boolean;
    termsAndAgreementsDate: string; // date
    workspaces: number[];
    markets: number[];
    marketsCodes?: string[];
    createdTime?: string; // date
    findInAccessibleOnly: boolean;
    companySector: string;
    shouldReviewDeclaredUsers?: boolean;
    companyOnTrial?: boolean;
    companyMinutesBeforeExpirationDate?: number;
    companyExpirationDate?: string;
}

export interface LightUserI {
    uid: string;
    email: string;
    firstname: string;
    name: string;
}

export enum UserDefaultRolesE {
    ADMINISTRATOR = 'ADMINISTRATOR',
    COMPANY_MANAGER = 'COMPANY_MANAGER',
    STANDARD_USER = 'STANDARD_USER',
    TEMPORARY_USER = 'TEMPORARY_USER',
    TRIAL_USER = 'TRIAL_USER',
    TRIAL_COMPANY_MANAGER = 'TRIAL_COMPANY_MANAGER',
    PUBLIC_USER = 'PUBLIC_USER',
    JURISQUARE_USER = 'JURISQUARE_USER'
}

export interface UserPropsI {
    bookImporterWorkspace?: string;
    ff_findInAccessibleOnly?: string;
    ff_timer_module?: string;
    findInAccessibleOnly?: string;
    ff_download?: string;
    lexprhId?: string;
    sb_creation?: string;
    sb_view?: string;
    sb_visibility_everyone?: string;
    defaultSearchFilters?: PublicationFilterI;
    profileType?: string;
}

export interface FreeUserI {
    userUuid: string;
    free: boolean;
    freeUserCategory?: FreeUserTypeE;
}

export interface UserToUploadI {
    companyId: number;
    licenseId: number;
    file?: File;
}

export interface IJurisquareUser {
    uuid: string;
    magazineId: number;
    subscriptionValidFrom: string;
    subscriptionValidTo: string;
    subscriptionActive: boolean;
}
