import { Directive, ElementRef, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AppE } from '../../../models/app';

@Directive({
    selector: '[appBookThumbnail]'
})
export class BookThumbnailDirective {
    constructor(private el: ElementRef<HTMLImageElement>) {
        if (environment.app === AppE.politeia) {
            el.nativeElement.src = 'images/politea/vignette.png';
        } else {
            el.nativeElement.src = 'images/thumbnails/vignette.png';
        }
    }

    @Input() set appBookThumbnail(image: string) {
        if (image) {
            this.el.nativeElement.src = image;
        }
    }
}
