import { MessageBus } from '../../../messaging/MessageBus';
import { Component, ViewChild } from '@angular/core';
import { Configuration } from 'src/app/constant/configuration';
import { EVENT_RESTRICTED_ACCESS_MESSAGE_SHOW } from 'src/app/services/restricted-access-popup.service';
import { CompanyI } from '../../../models/company';
import { AlertMixin } from '../../../mixins/alert/alert.mixin';
import { MixinBase } from '../../../mixins/crud/MixinBase';
import { MatStepper } from '@angular/material/stepper';

@Component({
    selector: 'app-restricted-access-popup',
    templateUrl: './restricted-access-popup.component.html',
    styleUrls: ['./restricted-access-popup.component.scss']
})
export class RestrictedAccessPopupComponent extends AlertMixin(MixinBase) {
    @ViewChild('matStepper') matStepper: MatStepper;
    showModal = false;
    company: CompanyI;
    askToUpgradePlan = false;

    constructor(messageBus: MessageBus, configuration: Configuration) {
        super();
        messageBus.channel(configuration.messageBusChannels.RESTRICTED_ACCESS_CHANNEL as string).subscribe(message => {
            if (message.message === EVENT_RESTRICTED_ACCESS_MESSAGE_SHOW) {
                this.showModal = true;
            }
        });
        this.initAlertMixin(messageBus.channel(configuration.messageBusChannels.ALERT_CHANNEL as string));
    }
    close(): void {
        this.showModal = false;
    }
}
