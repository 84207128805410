import { Configuration } from '../constant/configuration';
import { MessageBus } from '../messaging/MessageBus';
import { NotificationMessageService } from '../services/notification_message.service';
import { SpinnerService } from '../shared/directive/spinner/spinner.service';
import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MainForgetDialogComponent } from '../offline/forget-dialog/main-forget-dialog.component';

@Injectable({ providedIn: 'root' })
export class HttpClientInterceptor implements HttpInterceptor {
    numberLoadings = 0;

    constructor(
        private spinnerService: SpinnerService,
        private notificationMessageService: NotificationMessageService,
        private router: Router,
        private messageBus: MessageBus,
        private configuration: Configuration,
        private matDialog: MatDialog
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Force IE to disable auto-cache and perform the HTTP request
        const clonedReq = req.clone({
            headers: req.headers.set('Cache-Control', 'no-cache').set('Pragma', 'no-cache').set('Expire', '-1')
        });

        // Show spinner
        const showSpinner = clonedReq.headers.get('skipSpinner') !== 'true';
        // Catch Technical and Business Error
        const skipTechnicalAndBusinessError = clonedReq.headers.get('skipTechnicalAndBusinessError') === 'true';
        if (showSpinner && !clonedReq.url.startsWith('/webfront/statistic')) {
            this.showSpinner();
        }

        return next.handle(clonedReq).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.handleResponse(showSpinner);
                }
            }),
            catchError((error: HttpErrorResponse) => {
                void this.handleResponseError(error, showSpinner, skipTechnicalAndBusinessError);
                return throwError(error);
            }),
            // handle cancelled requests
            finalize(() => {
                if (showSpinner) {
                    this.hideSpinner();
                }
            })
        );
    }

    private handleResponse(showSpinner: boolean): void {
        if (showSpinner) {
            this.hideSpinner();
        }
    }

    private async handleResponseError(
        response: HttpErrorResponse,
        showSpinner: boolean,
        _skipTechnicalAndBusinessError: boolean
    ): Promise<void> {
        if (showSpinner) {
            this.hideSpinner();
        }
        let body = response.error instanceof Blob ? await response.error.text() : response.error;
        if (response?.error?.type === 'application/json' || typeof body === 'string') {
            body = JSON.parse(body);
        }
        // Catch Technical and Business Error
        const skipTechnicalAndBusinessError =
            _skipTechnicalAndBusinessError || response.headers.get('skipTechnicalAndBusinessError');
        if (
            (response.status === 400 || response.status === 404 || response.status === 412) &&
            !skipTechnicalAndBusinessError
        ) {
            if (body?.code === '102') {
                void this.router.navigate(['o/home']);
            } else if (['6', '820'].includes(body.code)) {
                this.alertCustomError(body?.type?.toLowerCase(), body?.code);
            } else {
                // Display a translated error message
                this.notificationMessageService.init(
                    'global.errors.title',
                    'global.errors.' + body?.type?.toLowerCase() + '.' + body?.code
                );

                // Append error message returned from server
                if (body?.message) {
                    const errorMessage = {
                        message: body?.message
                    };
                    this.notificationMessageService.initTranslationValues({}, errorMessage);
                }
                this.notificationMessageService.addLeftButton({ label: 'global.forms.cancel' });
                // If the error code is 52, this is a token linked expired.
                if (body?.code === '52') {
                    // Add a callback to redirect the user to the forgotten password screen
                    this.notificationMessageService.addRightButton({
                        label: 'global.forms.ok',
                        callback: () => {
                            this.matDialog.open(MainForgetDialogComponent);
                        }
                    });
                }
                this.notificationMessageService.show();
            }
        } else if (response.status === 401) {
            const currentRouteUrl: string = this.router.url;
            if (
                response.url.indexOf('webfront/user/current') === -1 &&
                currentRouteUrl !== '' &&
                currentRouteUrl.startsWith('/o/') &&
                !this.configuration.openUrls.find(openUrl => currentRouteUrl.startsWith(openUrl))
            ) {
                // In case of the user is not logged, we send an event catch by the router with the targeted URL.
                this.messageBus.channel(this.configuration.messageBusChannels.SESSION_CHANNEL).next({
                    data: { url: this.router.url },
                    message: 'lostSession'
                });
            }
        } else if (response.status === 403) {
            // the user is authenticated but the route is not available for him
            // TODO SHOULD SHOW MESSAGE
            // void this.router.stateService.go('main.online.home');
        }
    }

    private alertCustomError(type: string, code: string): void {
        this.notificationMessageService.init(
            `global.errors.${type}.${code}.title`,
            `global.errors.${type}.${code}.message`
        );
        this.notificationMessageService.addLeftButton({ label: 'global.action.ok' });
        this.notificationMessageService.show();
    }

    private showSpinner(): void {
        this.numberLoadings += 1;
        this.spinnerService.show();
    }

    private hideSpinner(): void {
        this.numberLoadings -= 1;
        if (this.numberLoadings < 1) {
            this.numberLoadings = 0;
            this.spinnerService.hide();
        }
    }
}
