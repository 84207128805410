import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { selectAllEntities, selectAllEntitiesApply, setEntities, withEntities } from '@ngneat/elf-entities';
import { PartnerI, PartnerTypeE } from '../models/partner';
import { syncState } from 'elf-sync-state';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

const store = createStore({ name: 'partner' }, withEntities<PartnerI>(), withRequestsCache<'partner'>());
export const skipWhilePartnersCached = createRequestsCacheOperator(store);
export const CACHED_PARTNERS_KEY = 'partner';

syncState(store);
persistState(store, { key: 'partners@store', storage: localStorageStrategy });
@Injectable({ providedIn: 'root' })
export class PartnerRepository {
    partners$ = store.pipe(selectAllEntities());
    lexnowPartners$ = store.pipe(
        selectAllEntitiesApply({
            filterEntity: partner => partner.type === PartnerTypeE.LEXNOW
        })
    );

    setPartners(partners: PartnerI[]): void {
        store.update(updateRequestCache('partner'), setEntities(partners));
    }
}
