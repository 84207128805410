import { tap } from 'rxjs/operators';
import { HttpClientService } from './http-client.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

export interface MarketI {
    id?: number;
    code: string;
}

@Injectable({ providedIn: 'root' })
export class MarketService {
    private marketList: Array<MarketI>;
    private allMarketsSubject = new BehaviorSubject<MarketI[]>([]);
    public allMarkets$: Observable<MarketI[]> = this.allMarketsSubject.asObservable();

    constructor(public httpService: HttpClientService) {
        this.httpService.get('/market').subscribe((value: MarketI[]) => {
            this.marketList = value;
            this.allMarketsSubject.next(value);
        });
    }

    get markets(): Array<MarketI> {
        return this.marketList;
    }

    async getMarkets(): Promise<Array<MarketI>> {
        if (!this.marketList) {
            return firstValueFrom(
                this.httpService.get(`/market`).pipe(
                    tap((value: Array<MarketI>) => {
                        this.marketList = value;
                        this.allMarketsSubject.next(value);
                    })
                )
            );
        }
        return this.marketList;
    }
}
