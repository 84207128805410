import mixpanel from 'mixpanel-browser';
import { Inject, Injectable } from '@angular/core';
import { Configuration } from '../constant/configuration';
import { CurrentUserI } from '../models/user';

export interface MixpanelConstants {
    prodToken: string;
    prodDomain: string;
    uatToken: string;
    uatDomain: string;
    actions: any;
}

@Injectable({ providedIn: 'root' })
export class MixpanelService {
    constants: MixpanelConstants;
    isInitialized = false;

    constructor(@Inject('window') private window: Window, configuration: Configuration) {
        this.constants = configuration.mixpanel;
    }

    initialize(): void {
        const token = this.chooseToken();
        if (token) {
            mixpanel.init(token, {
                api_host: 'https://api-eu.mixpanel.com',
                opt_out_tracking_by_default: false
            });
            if (mixpanel.has_opted_out_tracking()) {
                mixpanel.opt_in_tracking(); // this sets to true that user wants to be tracked by default
            }
            mixpanel.set_config({
                batch_flush_interval_ms: 1000
            });
            this.isInitialized = true;
        }
    }

    identify(userRaw: CurrentUserI): void {
        const user = {
            $email: userRaw.email,
            $name: userRaw.name + ' ' + userRaw.firstname,
            organizationId: userRaw.companyUuid,
            registrationDate: userRaw.termsAndAgreementsDate,
            userRole: userRaw.roles.join(' | '),
            $distinct_id: userRaw.uuid,
            $set: {
                $email: userRaw.email
            },
            organizationName: userRaw.companyName,
            preferredLanguage: userRaw.language
        };
        if (this.isInitialized) {
            mixpanel.identify(user.$distinct_id); // identify user
            mixpanel.register(user); // sets common data for all events
            mixpanel.people.set_once(user); // adds a person to global mixpanel if it does not exists
        }
    }

    track(actionType: string, actionProps?: any): void {
        if (this.isInitialized) {
            const time = new Date().toISOString();
            mixpanel.track(actionType, { ...actionProps, timestamp: time });
        }
    }

    reset(): void {
        if (this.isInitialized) {
            mixpanel.reset();
        }
    }

    chooseToken(): string {
        let token = null;
        if (this.window.location.hostname.toLowerCase().search(this.constants.uatDomain) >= 0) {
            token = this.constants.uatToken;
        } else if (this.window.location.hostname.toLowerCase().search(this.constants.prodDomain) >= 0) {
            token = this.constants.prodToken;
        }
        return token;
    }
}
