import {
    AfterContentInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    CheckBoxTreeItem,
    CheckBoxTreeTechnicalItem
} from '../collapsible-checkbox-tree/collapsible_checkbox_tree_item.type';
import {
    CheckStateItemUpdate,
    CollapsibleCheckboxTreeComponent
} from '../collapsible-checkbox-tree/collapsible-checkbox-tree.component';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-collapsible-checkbox-tree-dropdown',
    templateUrl: './collapsible-checkbox-tree-dropdown.component.html',
    styleUrls: ['./collapsible-checkbox-tree-dropdown.component.scss']
})
export class CollapsibleCheckboxTreeDropdownComponent implements AfterContentInit, OnInit, OnChanges {
    @ViewChild('collapsibleCheckboxTree', {
        static: true
    })
    private collapsibleCheckboxTree: CollapsibleCheckboxTreeComponent;
    private _filterQuery: string | null = null;
    _itemsTree: Array<CheckBoxTreeItem> = [];
    private filteredItemIdToCheckBoxTreeItem: { [id: string]: CheckBoxTreeTechnicalItem } = {};
    private leafItems: CheckBoxTreeTechnicalItem[] = [];
    appliedItems = new Set<string>();
    map = map;
    filteredItems: Array<CheckBoxTreeTechnicalItem> | null = null;
    selectedAmount = 0;

    @Input() title: string;
    @Input() defaultFlatSelectionIds: Set<string>;
    @Input() set itemsTree(value: Array<CheckBoxTreeItem>) {
        this.leafItems = [];
        this._itemsTree = value;
    }
    @Input() translationContext = '';

    // We can set ids to select here, instead of [defaultFlatSelectionIds] it will be selected
    // Exemple of usage: for ids come from routeParams, in [My searches] context
    @Input() attributesSelection: Set<string>;

    @Output() itemsCheckedStatusUpdated = new EventEmitter<Array<CheckStateItemUpdate>>();
    @Output() applySelectionIds = new EventEmitter<Set<string>>();
    @Output() checkedItemsIds$ = new BehaviorSubject<Set<string>>(new Set());

    set filterQuery(value: string) {
        this._filterQuery = value;
        this.onFilterChange(this._filterQuery);
    }
    get filterQuery(): string {
        return this._filterQuery;
    }

    ngOnInit(): void {
        this.appliedItems = this.returnAppliedOrDefault(null);
        this.appliedItems != null ? (this.selectedAmount = this.appliedItems.size) : (this.selectedAmount = 0);
    }

    returnAppliedOrDefault(values: Set<string>): Set<string> {
        return values || this.attributesSelection || this.defaultFlatSelectionIds;
    }

    ngAfterContentInit(): void {
        if (this._filterQuery != null) {
            this.onFilterChange(this._filterQuery);
        }
        this.collapsibleCheckboxTree.itemsCheckedStatusUpdated.subscribe(value =>
            this.itemsCheckedStatusUpdated.emit(value)
        );
    }

    handleSelectedItemsChange(selectedIds: Set<string>): void {
        this.checkedItemsIds$.next(selectedIds);
    }

    computeLeafItems(items: Array<CheckBoxTreeTechnicalItem>, leafItems?: CheckBoxTreeTechnicalItem[]): void {
        this.leafItems = leafItems ? leafItems : [];
        items.forEach(item => {
            if (item.children == null || item.children.length === 0) {
                this.leafItems.push(item);
            } else {
                this.computeLeafItems(item.children, this.leafItems);
            }
        });
    }

    onFilterChange(filter: string): void {
        if (filter.length === 0) {
            this.filteredItems = null;
        } else {
            this.filteredItems = this.filterTree();
            this.collapsibleCheckboxTree.technicalItems = this.filteredItems;
        }
    }

    onSubmitClick(): void {
        this.selectedAmount = this.checkedItemsIds$.value.size;
        this.appliedItems = this.checkedItemsIds$.value;
        this.applySelectionIds.emit(this.checkedItemsIds$.value);
    }

    onRemoveClick(): void {
        this.resetSelection();
        this.applySelectionIds.emit(new Set());
    }

    resetSelection(): void {
        this.appliedItems = new Set();
        this.selectedAmount = 0;
        this.collapsibleCheckboxTree.cleanupSelection();
    }

    filterTree(): CheckBoxTreeTechnicalItem[] {
        this.filteredItemIdToCheckBoxTreeItem = {};
        const heads: CheckBoxTreeTechnicalItem[] = [];
        const buildBottomTop = (item: CheckBoxTreeTechnicalItem) => {
            const sameAlreadyFilteredItem = this.filteredItemIdToCheckBoxTreeItem[item.internalUuid];
            let needToPushItemIntoHigherLevelCollection;
            if (sameAlreadyFilteredItem) {
                // this is parent with some children that persist in the result, so we need to display it.
                if (sameAlreadyFilteredItem.parent) {
                    buildBottomTop(sameAlreadyFilteredItem.parent);
                }
                needToPushItemIntoHigherLevelCollection = true;
            } else {
                // this is leaf item or joint with children not persist in the result.
                needToPushItemIntoHigherLevelCollection =
                    item.data.label.toUpperCase().indexOf(this._filterQuery.toUpperCase()) >= 0 ||
                    (!!item.children && item.children.length > 0);
            }

            if (needToPushItemIntoHigherLevelCollection) {
                if (item.parent) {
                    let sameAlreadyFilteredParent = this.filteredItemIdToCheckBoxTreeItem[item.parent.internalUuid];
                    if (!sameAlreadyFilteredParent) {
                        sameAlreadyFilteredParent = {
                            data: item.parent.data,
                            children: [],
                            expanded: true
                        };
                        this.filteredItemIdToCheckBoxTreeItem[item.parent.internalUuid] = sameAlreadyFilteredParent;
                    }
                    sameAlreadyFilteredParent.children.push(item);
                    buildBottomTop(sameAlreadyFilteredParent);
                } else {
                    if (heads.indexOf(item) === -1) {
                        heads.push(item);
                    }
                }
            }
        };
        this.leafItems.forEach(item => {
            buildBottomTop(item);
        });
        return heads;
    }

    handleOpenChange(isOpen: boolean): void {
        if (isOpen) {
            this.collapsibleCheckboxTree.resetToDefault();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.attributesSelection && !changes.attributesSelection.firstChange) {
            this.attributesSelection = changes.attributesSelection.currentValue;
            this.ngOnInit();
        }
    }
}
