import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AppE } from '../../../models/app';

@Directive({
    selector: '[appIsDefaultApp]'
})
export class IsDefaultAppDirective implements OnInit {
    constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>) {}

    ngOnInit(): void {
        if (environment.app === AppE.default) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
